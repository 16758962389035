@import "../../scss/mixin.scss";

.swiper-holder {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
    &.hideswiper {
        visibility: hidden;
    }
}
.swiper {
    z-index: 1;
    width: 100%;
    height: 100vh;
}
.holdingslide {
    width: 100%;
    height: 100vh;
    background: 50% 50% no-repeat;
    background-size: cover;
    &.slide1 {
        background-image: url(../../../public/images/1.jpg);
    }
    &.slide2 {
        background-image: url(../../../public/images/2.jpg);
    }
    &.slide3 {
        background-image: url(../../../public/images/3.jpg);
    }
}

.body-hero {
    height: 100vh;
    width: 100%;
    background-color: $darkGreen;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .intro-anim {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        //second coords are the point, 1st top right, 3rd bottom right
        clip-path: polygon(
            calc(101% + 25vh) 0%,
            101% 50%,
            calc(101% + 25vh) 100%,
            0 100%,
            0 0
        );
        transition: clip-path 2s;
        &.hideintro {
            clip-path: polygon(0% 0%, -25vh 50%, 0% 100%, -120% 100%, -120% 0);
        }
    }

    .icon {
        position: absolute;
        bottom: -50.5px;
        z-index: 50;
    }
    video {
        position: absolute;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        top: 0;
        left: 0;
    }
    div {
        overflow-y: hidden;
        position: relative;
        z-index: 2;
        h1 {
            overflow-y: hidden;
            color: white;
        }
        @include mobile {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            svg {
                width: 90%;
            }
        }
    }
    .logo {
        position: absolute;
        top: 50px;
        height: 50px;
        z-index: 20;
    }
}
.footer-hero {
    width: 100%;
    background: linear-gradient(
        180deg,
        $darkGreen 0%,
        #4c6164 36.63%,
        #ebbfba 100%
    );

    .footer-pattern {
        background-image: url("../../../public/images/bg25.png");
        background-size: cover;
        padding: 6rem 0;
        width: 100%;
        @include mobile {
            height: fit-content;
            padding: 1.5rem;
        }
    }
    &.two {
        background: unset;
        .footer-pattern {
            background-image: unset;
        }
    }
    background-size: cover;
    @include mobile {
        height: fit-content;
    }
    .footer-top {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 50%;
        padding: 0 0 10rem;
        @include mobile {
            height: fit-content;
            justify-content: flex-start;
            //align-items: flex-start;
            padding-top: 3rem;
            padding: 3rem 0 0;
        }

        h2 {
            text-align: center;
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }

            span {
                color: #ebbfba;
            }
        }
    }
    .footer-bot {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        height: 50%;
        color: white;
        @include mobile {
            height: fit-content;
            align-items: flex-start;
            padding-top: 1.5rem;
            justify-content: flex-start;
        }
        h3 {
            width: 80%;
            border-top: 1px solid $pink;
            text-align: center;
            padding-top: 3rem;
            font-size: 21px;
            @include mobile {
                padding-top: 1.5rem;
                text-align: left;
                width: 100%;
            }
        }
        .agents-wrapper {
            padding: 3rem 0;
            display: flex;
            justify-content: space-evenly;
            width: 80%;
            width: 100%;
            @include mobile {
                padding: 1.5rem 0;
                flex-direction: column;
                align-items: flex-start;
            }
            .img-wrapper {
                padding: 1.5rem 0;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 100%;
                    max-height: 45.6px;
                    @include mobile {
                        height: 30px;
                        width: fit-content;
                    }
                }
                @include mobile {
                    align-items: flex-start;
                    justify-content: flex-start;
                    width: fit-content;
                }
            }
            .details {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                @include mobile {
                    align-items: flex-start;
                }

                p {
                    font-size: 25px;
                    line-height: 32px;
                    @include mobile {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
                a {
                    color: white;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.bottom {
    display: flex;
    justify-content: center;
    padding: 3rem 0;
    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        p {
            color: white;
            padding-bottom: 1.5rem;
            text-align: center;
        }
    }
}

.logo-link {
    z-index: 50;
    cursor: pointer;
}

.video-hero {
    background: 
        linear-gradient(
            180deg,
            $darkGreen 0%,
            #4c6164 36.63%,
            #ebbfba 100%
        ),
        url("../../../public/images/bg25.png");
    background-size: cover;
}
