@import "./mixin.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}

body {
  background-color: $darkGreen;
}

@font-face {
  font-family: 'Safiro';
  src: url('../../public/fonts/Safiro-Regular.woff2') format('woff2'),
      url('../../public/fonts/Safiro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h1 {
  color: white;
  font-size: 111.43px;
  line-height: 146px;
  font-family: "Safiro";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  @include mobile {
    font-size: 50px;
    line-height: 60px;
  }
}

h2 {
  color: white;
  font-size: 49px;
  font-family: "Safiro";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  @include mobile {
    font-size: 30px;
  }
}
h3 {
  font-family: "Safiro";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

p {
  font-family: "Safiro";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


.contain-wrapper{
  display: flex;
  justify-content: center;
  width: 100%;
}
.contain{
  width: 100%;
  max-width: min(100%, 2000px);
}